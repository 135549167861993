<template>
  <div>
    <Edit
      :item="currentItem"
      :access="currentAccess"            
      :update="updateItem"      
      :close="closeEdit"
      :descSingular="descSingular"
    ></Edit>
    <div>
      <div class="content">
        <div class="content-wrapper">
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="descPlural">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Ordem</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items">
                      <td><strong>{{ item.descricao }}</strong></td>
                      <td>
                        <span class="label primary">{{ item.ordem }}</span>
                      </td>
                      <td class="actions">
                        <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import Edit from './Edit';

export default {
  name: 'secoesrodape',
  components: {    
    Edit,
  },
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      items: [],      
      modulos: [],
      currentItem: {},
      currentAccess: false,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_SECAO_RODAPE'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadSecoesRodape() {
      this.loading = true;
      get('/admin/site-secoes-rodape')
        .then((json) => {
          this.items = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },    
    openEdit(item, showLoading = true) {
      this.currentItem = clone(item);
      if (showLoading) {
        this.loading = true;
      }
      get(`/admin/site-secoes-rodape/${this.currentItem.id}`).then((json) => {
        this.currentAccess = json;
        if (showLoading) {
          this.loading = false;
          this.$modal.show('secoesRodapeEdit');
        }
      });
    },
    closeEdit() {
      this.$modal.hide('secoesRodapeEdit');      
    },
    updateItem() {
      const { id } = this.currentItem;
      put(`/admin/site-secoes-rodape/${id}`, this.currentItem)
        .then((json) => {
          this.closeEdit();
          this.loadSecoesRodape();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadSecoesRodape();    
    this.loadDescricaoModulo();
  },
};
</script>
